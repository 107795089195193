.clear-global-search {
  position: absolute;
  cursor: pointer;
  right: 10px;
}

.cross-button{
  /* height: 16px;
  width: 16px; */
}

.search-global-icon {
  width: 18px;
  margin-right: 20px;
  padding-top: 1%;
  padding-bottom: 1%;
  vertical-align: middle;
}

.global-search {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: end;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  align-items: center;
}

.search-global-icon.active {
  position: absolute;
  left: 10px;
}

.search-bar {
  padding-left: 34px !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  border-radius: 0 !important;
}


@media only screen and (max-width: 767px) {

  .search-global-icon {
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .search-bar {
    padding-top: 2% !important;
    padding-bottom: 2% !important;
  }

}