.responsiveTable thead, .responsiveTable th{
    position: sticky;
    top: 0;
    background: #DEDFE0 0% 0% no-repeat padding-box !important;
}

.responsiveTable>:not(caption)>*>* {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.responsiveTable {
    border-collapse: separate !important;
    border-spacing: 0 10px;
    /* font-size: 14px !important; */
    background: #FAFAFA 0% 0% no-repeat padding-box;
    /* max-height: 10rem; */
    min-width: max-content !important;
    /* width: max-content !important;a */
}

.responsiveTable,
.responsiveTable thead {
    opacity: 1;
    margin-bottom: 0 !important;
}

.responsiveTable tr:hover td:last-child {
    /* box-shadow: 0px 3px 6px #0000001A !important; */
    border-right: 1px solid #0745D3 !important;
}

.responsiveTable tr:hover td:first-child {
    /* box-shadow: 0px 3px 6px #0000001A !important; */
    border-left: 1px solid #0745D3 !important;
}

.responsiveTable tr:hover td {
    /* box-shadow: 0px 3px 6px #0000001A !important; */
    border-top: 1px solid #0745D3 !important;
    border-bottom: 1px solid #0745D3 !important;
}


.responsiveTable tr :hover,
.responsiveTable td :hover {
    box-shadow: none !important;
}

.responsive-td {
    /* max-width: 30% !important;
    max-width: 200px !important; */
    padding-left: 10px;
    /* padding-right: 5px; */
}



.table-th {
    padding-left: 10px !important;
    font-size: 12px;
}


.global-search-bar {
    /* margin-bottom: 1%; */
    padding: 0% !important;
    margin: 0 !important;
}

.global-search-col {
    margin-right: 0;
}

.download-btn-col {
    width: max-content !important;
    margin-right: 0;
}


.status {
    border-right: 8px solid #A4A5AA !important;
    width: 0 !important;
}


.responsive-td.Inactive,
.responsive-value.Inactive {
    color: #A4A5AA;
    font-family: WorkSans-SemiBold;
    size: 14px;
}

.responsive-td.Active,
.responsive-value.Active {
    color: #1CBA23;
    font-family: WorkSans-SemiBold;
    size: 14px;
}

.status.Active {
    border-right: 8px solid #1CBA23 !important;
    width: 0 !important;
}

.th-status {
    padding-left: 8px !important;
}

.table-checkbox {
    padding-left: 9px;
}

.table-checkbox .form-check-input:not(:checked) {
    background: transparent !important;
    border: 1px solid rgba(0, 0, 0, .25);
}

.responsiveTable th {
    font-weight: normal;
    font-size: 12px;
    height: 30px;
}


tr,
th,
td,
thead,
tbody {
    border: none !important;
}

tbody {
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

.responsiveTable td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    height: 43px;
}



.icon {
    display: flex;
    justify-content: flex-end;
     /* height: 43px; 
     width: 60px; */
    /* padding-bottom: 14px !important; */
}

/* .responsiveTable tbody :hover .table-icon {
    visibility: visible;
} */


.search-col {
    display: flex;
    justify-content: space-between;
    padding: 0% !important;
}

.add-btn {
    background: #084CE8 0% 0% no-repeat padding-box !important;
    border-radius: 0 !important;
    height: 48px !important;
}

.add-btn.disabled {
    background: #C0C1C4 0% 0% no-repeat padding-box !important;
    color: #FFFFFF !important;
    border: 0 !important;
}

.add-btn.hide {
    display: none;
}

.add-btn-label {
    display: flex;
    width: max-content;
}

.add-btn-img {
    margin-left: 15px;
}

.search-bar-right {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: end;
    height: 48px;

}

.filter-selected {
    border-radius: 50px 50px;
    background-color: #B0F1C9;
    width: fit-content;
    padding: 3px;
    margin-top: 1%;
    color: #767980;
}

.filter-selected-label {
    font-size: 12px;
    padding: 10px;
}

.filter-selected-cross {
    margin-right: 3px;
}

.filter-selected-col {
    display: flex;
}

 .responsiveTable .fixed {
    position: sticky;
    z-index: 2;
} 



@media only screen and (max-width: 767px) {
    .add-btn-img {
        margin-left: 1px !important;
    }

    .add-btn-label {
        padding-left: 16px !important;
        padding-right: 16px !important;
        word-wrap: break-word !important;
        display: inline-table !important;
        width: inherit !important;
    }


    .responsive-card {
        padding: 1% !important;
        border-top: 8px solid #A4A5AA !important;
        border-radius: 0 !important;
        height: auto;
        border: 0 !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

    .responsive-card.Active {
        border-top: 8px solid #1CBA23 !important;
    }

    .responsive-card.Inactive {
        border-top: 8px solid #A4A5AA !important;
    }

    .custom-grid {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

    .responsive-div span {
        width: 50%;
    }

    .card-body {
        padding: 5px !important;
    }


    .responsive-card-header {
        background: #FFFFFF 0% 0% no-repeat padding-box !important;
        border: 0 !important;
        display: flex;
        justify-content: space-between;
        margin-left: -10px;

    }

    .search-col {
        flex-direction: row !important;
        align-items: center;
    }

    .search-bar-right {
        margin-bottom: 3%;
        display: flex;
        width: 100%;
        position: relative;
        justify-content: end;
        /* padding: 1%; */
    }

    .add-btn {
        margin-bottom: 2%;
        width: 100%;
    }

    .add-btn.hide {
        display: none;
    }

    .column-grid-icon-gray {
        display: none;
    }

}