.download-btn {
  float: right;
  padding-right: 15px;
  margin-left: 1%;
  width: 48px;
  height: 48px;
  border: 1px solid #0745d3;
  padding-left: 15px;
}

