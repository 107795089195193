.breadcrum {
  background-color: #E6EDFD;
  padding: 3px 0 3px 1%;
}
.breadcrum *{
  font-size: 12px !important;
  text-decoration: none;
}
.breadcrumb{
  margin: 0 !important;
}

.breadcrum-parent *{
  color: black;
}

.breadcrum-child *{
  color: #084CE8;
}

/* Mobile View Start */
@media only screen and (max-width: 767px) {
  .breadcrumb {
    justify-content: center;
  }
  .breadcrum{
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

/* Mobile View End */