
 .g-input input {
  border: 1px solid #ddd;
  display: block;
  padding: 10px 10px 10px 15px;
  border-radius: 0;
  width: 100%;
  box-sizing: border-box;
  font-family: "WorkSans-Regular";
  font-size: 14px;
  color: #6F6F6F;
}

.g-input input:focus {
    outline: 0;
    font-family: "WorkSans-SemiBold";
    font-size: 14px;
    color: #000000;
}
.g-input label.active,.g-input input:focus+label {
    transform: translateX(6px) translateY(-81%);
    font-size: 14px;
    color: #6f6f70;
    font-family: "WorkSans-Regular";
}

input[type="date"].add-date{
  color: #ffffff;
}
.g-input label.active,.g-input input[type="date"]:focus+label {
  transform: translateX(6px) translateY(-81%);
  font-size: 14px;
  color: #6f6f70;
  font-family: "WorkSans-Regular";
}

input[type="date"]::before {
  content: attr(placeholder);
  position: absolute;
  color: #6f6f70;
}

input[type="date"]:focus,
input[type="date"]:valid {
  color: #000000;
}

input[type="date"]:focus::before,
input[type="date"]:valid::before {
  content: "";
}
