/* column-grid start*/
.column-selector {
    width: max-content !important;
    margin-right: 0;
}

.column-grid-icon-gray {
    background-color: #FFFFFF;
    float: right;
    padding-right: 15px;
    margin-left: 1%;
    width: 48px;
    /* border: 1px solid #0745d3; */
    padding-left: 15px;
}

.column-grid-icon-blue {
    background-color: #FFFFFF;
    float: right;
    padding-right: 15px;
    margin-left: 1%;
    width: 48px;
    border: 1px solid #0745D3;
    padding-left: 15px;
}

.column-grid-container {
    width: 200px !important;
    border-radius: 0px !important;

    /* width: fit-content !important; */
    padding-bottom: 3% !important;
    /* top: 20% !important; */
}


/* column-grid end*/

@media only screen and (max-width: 767px) {

    /* column selector for mobile start*/
    .column-grid-container {
        display: none;
    }

    .column-selector {
        display: none;
    }

    .column-selector-div {
        display: none;
    }

    .session-col {
        margin-left: 12px
    }

    /* column selector for mobile  end*/

}