.nav-list-item {
    /* padding-left: 5%; */
    /* height: 2%; */
    color: #000000 !important;
    text-decoration: none;
    margin-top: 5%;
    margin-top: 5%;
}

.home.closed .nav-list-item{
    display: flex;
    justify-content: center;
    margin-top: 10%;
}

.parent .menu-name{
    font-family: WorkSans-SemiBold;
}

.menu {
    margin-left: 10%;
    font-size: 12px;
}

.menu-name {
    margin-left: 7%;
    padding-top: 7%;
}


.nav-sub-menu {
    list-style-type: none;
    padding-left: 3rem;
}

.sidebar-body.closed .menu-arrow,.sidebar-body.closed .menu-name {
    display: none
}

.menu-arrow {
    float: right;
    cursor: pointer;
}
.nav-item{
    margin-top: 25px;
}

.home.closed .nav-item{
    /* justify-content: center; */
    margin-top: 25px;
}