.download-btn {
  float: left !important;
  padding-right: 15px;
  margin-left: 1%;
  width: 48px;
  height: 48px;
  border: 1px solid #0745d3;
   padding-left: 15px;
   
}

.files-note {
  color: #000000;
}

.upload-btn {
  padding-right: 6px;
  margin-left: 1%;
  width: 32px;
  height: 32px;
  border: 1px solid #0745d3;
  padding-left: 6px;
}

.drop-file-input__label {
  border: 1px solid #C0C1C4;
  opacity: 1;
  padding: 8px 16px 8px 18px;
}

.attach-note-color2 {
  font: 10px;
  text-align: left;
  letter-spacing: 0px;
  color: #A0A0A0;
  opacity: 1;
}

.attachment-remove {
  width: 30px;
  height: 30px;
  align-items: center;
  margin-left: 1%;
  padding-bottom: 5px;
  float: right;

}

.success-right {
  width: 30px;
  height: 30px;
  align-items: center;
  margin-left: 1%;
  padding-bottom: 5px;
  float: right;

}

.lbl-signature {
  text-align: left;
  margin-top: 20%;
  font-weight: bold;
  font-family: "WorkSans-Regular";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  top: 85px;
  left: 304px;
  width: 75px;
  height: 19px;
  font-size: 16px;
}




/* .download-btn {
    float: right;
    padding-right: 15px;
    margin-left: 1%;
    width: 48px;
    height: 48px;
    border: 1px solid #0745d3;
    padding-left: 15px;
  }
  
  .upload-btn{
    padding-right: 6px;
    margin-left: 1%;
    width: 32px;
    height: 32px;
    border: 1px solid #0745d3;
    padding-left: 6px;
  }
  .drop-file-input__label{
    border: 1px solid #C0C1C4;
    opacity: 1;
    padding: 8px 16px 8px 18px;
  }
  .attach-note-color2{
    font: 10px;
  text-align: left;
  letter-spacing: 0px;
  color: #A0A0A0;
  opacity: 1;
  }
  
  .attachment-remove{
  width: 30px;
  height: 30px;
  align-items: center;
  margin-left: 1%;
  padding-bottom: 5px;
  float: right;
  
  }
  
  .success-right{
    width: 30px;
    height: 30px;
    align-items: center;
    margin-left: 1%;
    padding-bottom: 5px;
    float: right;
    
    } */