/*Common CSS Start*/
.page-title {
  font-size: 28px !important;
}

.page-sub-title {
  color: #6f6f6f;
  padding-bottom: 1%;
}

.form-select:focus,
.form-control:focus {
  border-color: #ddd !important;
  box-shadow: none !important;
}
.form-check-input:focus ,.select__control--is-focused.css-t-7ipsp-control{
  box-shadow: none !important;
}

.fieldLabel.Active {
  color: #1CBA23;
}

.fieldLabel.Inactive {
  color: #A4A5AA;
}

.css-1dimb5e-singleValue{
  left:7px !important;
}

/*Common CSS end*/

/* Login page start*/
.login-container {
  position: relative;
}

.login-image {
  height: 100vh;
  width: 100vw;
}


.logo {
  /* height: 20%;
  width: 20%; */
  padding-top: 30px;
  margin-top: 17px;
}

.login-box {

  /* left: 65%; */
  width: 353px;
  border-radius: 16px;
  position: absolute;
  top: 25%;
  /* left: 65%; */
  right: 5%;
  /* width: 40%; */
  height: 340px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Login-btn-container {
  width: 95%;
}

.login-btn {
  width: inherit;
  border-radius: 0 !important;
  font-family: "WorkSans-SemiBold",sans-serif !important;

  margin-bottom: 10%;
  background: #084CE8 0% 0% no-repeat padding-box !important;
}


.login-header {
  font-size: 27px;
  font-family: "WorkSans-SemiBold",sans-serif !important;

}

.carousel-caption {
  text-align: left !important;
  left: 10% !important;
  top: 25% !important;
  right: unset !important;
  bottom: unset !important;
  font-size: 20px;
  font-family: "WorkSans-Medium",sans-serif;
  width: 50%;
}

.caption-title {
  font-family: "WorkSans-SemiBold",sans-serif;
  font-size: 60px;
  width: fit-content;
  height: fit-content;
}

.caption-sub-title {
  font-family: "WorkSans-Medium";
  font-size: 20px;
  width: fit-content;
  height: fit-content;
  margin-top: 10px;
}

.caption-title.first {
  background-color: #99CC34;
}

.caption-title.second {
  background-color: #06AFFF;
}

.caption-title.third {
  background-color: #DB2574;
}

.carousel-indicators [data-bs-target] {
  width: 9px !important;
  height: 9px !important;
  border-radius: 50% 50% !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  text-indent: 0 !important;
  color: #FFFFFF !important;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  opacity: 1 !important;
}

.carousel-indicators {
  right: unset !important;
  margin-left: 10% !important;
  bottom: 5% !important;
}

.carousel-control-prev,
.carousel-control-next {
  display: none !important;
}

.carousel-indicators .active {
  background: none !important;
  font-size: 30px;
  font-family: "WorkSans-SemiBold";
  display: flex;
  align-items: center;
  margin-right: 40px !important;
}

.carousel-indicators .active:nth-child(1):after {
  content: "01" !important;
}

.carousel-indicators .active:nth-child(2):after {
  content: "02" !important;
}

.carousel-indicators .active:nth-child(3):after {
  content: "03" !important;
}

.carousel-indicators .active:nth-child(4):after {
  content: "04" !important;
}

.carousel-indicators .active:nth-child(5):after {
  content: "05" !important;
}

.carousel-indicators .active:nth-child(6):after {
  content: "06" !important;
}


/* Login page end*/

/*Home */
.home {
  background: #F5F5FF 0% 0% no-repeat padding-box;
  display: flex;
  height: 100vh;
}

.center-panel {
  width: 100%;
}

.home.open .center-panel {
  width: 75% !important;
}


/*Home end*/


/* Side bar start*/
.side-bar {
  width: 25% !important;
  /* width: fit-content !important; */
  transition: all 0.4s ease;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  /* height: 100vh; */
}

.sidebar-head {
  /* float: right; */
  height: 35px;
  display: flex;
  justify-content: end;
  box-shadow: 3px 3px 6px #f2f2f2;
  padding-right: 5%;
}

.head-toggle {
  align-self: center;
}

.side-bar:has(.sidebar-body.closed) {
  width: 3% !important;
}

.home.closed .sidebar-head {
  justify-content: center !important;
}

.sidebar-body {
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.sidebar-body.closed .footer-logo {
  width: 2%;
}

.nav-list {
  list-style-type: none;
}

.footer {
  display: flex;
  justify-content: center;
}

.footer-logo {
  position: fixed;
  bottom: 1%;
}

/* Side bar end*/

/* Header start*/
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* max-height: 20%; */
  /* height: fit-content; */
  height: 35px;
  padding: 1%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
}

.user-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 1%; 
  align-items: center;
}


.user-logo {
  background: #fde6f9 0% 0% no-repeat padding-box;
  border-radius: 50%;
  /* height: max-content;
  width: max-content; */
  height: 2.5rem;
  width: 2.5rem;
  align-items: center;
  justify-content: center;
  display: flex;
}
.user-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.user-info {
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem;
  justify-content: center;
  align-items: flex-start;
  color: #5f5f5f;
  padding: 0.25rem;
  font-size: 12px;
}


.user-name {
  font-family: "WorkSans-Medium";
  line-height: 1rem;
  color: #000000;
}

.user-email {
  line-height: 1rem;
  color: #6f6f6f;
}


.sidebar-head-header {
  display: none;
}

.app-name {
  display: flex;
  align-items: center;
}

/* Header end */

/* Main start*/

.main-body {
  padding: 1% 1% 0 1%;
}

/* Main end */

/* Modal Title start */

.modal-title {
  font-family: "WorkSans-SemiBold";
  font-size: 16px !important;
}

/* Modal Title end*/
/* Toast Container start */
.toast-container {
  width: -webkit-fill-available !important;
  padding: 0 !important;
  margin: 0 !important;
  top: 7% !important;
  right: 0 !important;
  left: 3%;
  z-index: 0 !important;
}

.home.closed .toast-container {
  left: 3%;
}

.home.open .toast-container {
  left: 25% !important;
}

.Toastify__toast {
  border-radius: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: 1% !important;
  /* height: 40px !important; */
}

.Toastify__toast-body>div:last-child {
  font-family: "WorkSans-Regular";
  font-size: 12px;
}

/* Toast Container End */
/* for Input Placeholder start*/
.lblstatus{
  transform: translateY(-50%);
}
.sw-status{
  vertical-align: text-top;
  transform: translateY(-50%);

}

.text-field-with-error,
.text-field-with-error:focus,
.text-field-with-error .select__control.css-13cymwt-control {
  border-color: #f8031c !important;
  
}

.text-label-with-error{
  border-color: #f8031c !important;
  color:  #f8031c !important; 
}

.optional {
  font-size: 14px !important;
  font-family: "WorkSans-MediumItalic" !important;
}

.form-label {
  font-size: 14px;
}
.form-select {
  border-radius: 0;
  height: 39px;
}

/* .g-input css start */
.g-input {
  position: relative;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
}

.g-input label{
  font-family: "WorkSans-Regular";
  box-sizing: border-box;
  padding: 3px;
  background-color: #FFFFFF;
  /* font-weight: bold;  */
  backdrop-filter: blur(3px);
  font-size: 14px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  /* color: #000000;  */
  color: #6F6F6F;
  position: absolute;
  top: 7px;
  left: 7px;
  /* z-index: 1; */ 
  /* cursor: text; */
  /* display: inline-block; */
    max-width: 170px !important;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  
}
.g-input.fill {
  display: block;
  width: 100%;
}

  /* .g-input css End */



.gray-shade {
  color: #6f6f70;
}

.fieldLabel {
  color: #6f6f70;
  font-size: 12px;
}

.column-heading {
  left: 20px;
  position: relative;
  text-align: center;
  height: 100px;
}

.menu-header {
  display: flex;
}

.text-size {
  font-size: 14px;
  padding: 5px;
  align-content: center;
  text-align: center;
  color: #6F6F6F;
}

.menuhead-col {
  text-align: left;

}

.submenuhead-col {
  margin-left: 3%;
}

.session-col {
  margin-left: 30px
}

.select-all-row {
  border-bottom: 1px solid #C0C1C4;
  background-color: #FFFFFF;
  font-size: 14px;
  padding: 5px;
  margin-right: 0% !important;
  margin-left: 0% !important;
  /* border-left: 1px solid #C0C1C4;
  border-right: 1px solid #C0C1C4; */
  align-content: center;
  text-align: center;
  color: #000000;
}

.select-all-row *,
.checkbox-check.parent * {
  font-family: "WorkSans-SemiBold" !important;
  color: #000000 !important;
}


.checkbox-check .form-check-label {
  color: #A4A5AA;
}

.checkbox-check:has(.form-check-input:checked) .form-check-label {
  color: #000000 !important;
}

.downarrow-img {
  float: right;
  padding-right: 4%;
}

.txt-input {
  padding: 1%;
}

.form-check-input[type=checkbox]:checked {
  background-image: url('./assets/checkboxWithTick.svg') !important;
  /* background-color: #E6EDFD !important; */
  background-size: auto;
  /* background-color: #0745D3 !important; */
  /* accent-color: #E6EDFD !important;  */
  /* color: #0745D3 !important;  */
}

.form-check-input[type=radio]:checked {
background-color: #0636A5 !important;
    border-color: #0636A5 !important;
    accent-color: #0636A5 !important;
    background-image: none !important;
    appearance:auto !important;
}

.form-control:disabled {
  background-color: #FFFFFF !important;
  color: #94969C;
}

.form-select:disabled {
  background-color: #FFFFFF !important;
  color: #94969C;
}

.parentmenu-row {
  background-color: #F5F5FF;
  font-size: 14px;
  padding: 5px;
  margin-right: 0% !important;
  margin-left: 0% !important;
  align-content: center;
  text-align: center;
  color: #000000;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.submenu-row {
  font-size: 14px;
  padding: 5px;
  align-content: center;
  text-align: center;
  color: #0e1220;
}

.btn-color {
  background-color: #084CE8 !important;
  border-radius: 0 !important;
}

.reset-button {
  border-radius: 0 !important;
  border-color: #084CE8 !important;
  color: #084CE8 !important;
}

.right-alignement {
  float: right;
}

.center-align {
  text-align: center;
}

.tabledata-format {
  border: 1px solid #C0C1C4;
  padding: 0px 0px 10px 0px;
}

.status-switch {
  margin-left: 1%;
}

.btn-position {
  text-align: right;
}


.form-check:checked {
  background-color: #16911b;
  border-color: #16911b;
}

.semiBoldToggleButton {
  font-family: "WorkSans-SemiBold" !important;
}

/* RoleMaster End */

/* AddEditUser Start */

.modal-header {
  display: flex;
  flex-direction: row-reverse;
}

.role-drop {
  width: 100%;
  height: 55%;
}

.userModal {
  padding-top: 2%;
}

.actionbtn {
  display: flex;
  justify-content: space-between;
}

.modal {
  background: rgba(59, 63, 74, 0.85);
}

.modal-content {
  border-radius: 0 !important;
}

.select__control.select__control--is-disabled.css-16xfy0z-control {
  height: 43px !important;
  border-radius: 0 !important;
  background-color: white;
}

.select__single-value.select__single-value--is-disabled.css-olqui2-singleValue {
  color: #94969C !important;
}

.readOnly {
  color: #94969C !important;
}



.select__control.css-13cymwt-control {
  height: 43px;
  border-radius: 0;
}

.css-t3ipsp-control {
  min-height: 0 !important;
  border: 1px solid #ced4da !important;
  color: #212529 !important;
  border-radius: 0.2rem !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
}

/* .css-t3ipsp-control {
  box-shadow: 0 0 1px 4px #c2dbfe !important;
  border-color: #86b7fe !important;
} */

.css-tj5bde-Svg {
  fill: black !important;
  stroke: #ffffff !important;
  stroke-width: 1 !important;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.css-1nmdiq5-menu {
  font-weight: 200 !important;
}

/* AddEditUser End */

/* AddEditGeneralDropdown start */

.form-control:disabled {
  background-color: white !important;
  color: #94969C;
}

/* AddEditGeneralDropdown end */

/* Spinner*/

.spinner {
  position: absolute;
  width: 5rem !important;
  height: 5rem !important;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
  z-index: 1000;
}

.spinner-box {
  display: grid;
  position: fixed;
  z-index: 1060;
  inset: 0;
  background: rgba(59, 63, 74, 0.85);
}

/* Spinner end*/

/* swal */

.swal2-container {
  background: rgba(59, 63, 74, 0.85) !important;
}

.swal2-title {
  font-family: "WorkSans-SemiBold" !important;
  font-size: 16px;
  padding: 0 !important;
  margin: 0 !important;
  /* display: flex !important;
  justify-content: flex-start; */
}

.swal2-html-container {
  /* font-family: "WorkSans-SemiBold" !important; */
  font-size: 14px !important;
  padding: 0 !important;
  margin: 0 !important
}

.swal2-styled.swal2-confirm {
  background: #084CE8 0% 0% no-repeat padding-box;
  border-radius: 0;
  font-size: 14px !important;
  width: 50%;
  height: 48px;
}

.swal2-styled.swal2-cancel {
  /* padding-left: 40px !important;
  padding-right: 40px !important; */
  background-color: #fff !important;
  color: #084CE8 !important;
  border: 1px solid #084CE8 !important;
  /* font-family: "WorkSans-SemiBold" !important; */
  border-radius: 0;
  font-size: 14px !important;
  width: 50%;
  height: 48px;
}

.swal2-styled.swal2-cancel:hover {
  background-color: #084CE8 !important;
  color: #fff !important;
}

.swal2-styled.swal2-confirm:hover {
  background-color: #084CE8 !important;
  border: 1px solid #084CE8 !important;
}

.swal-confirmation {
  width: 400px;
  height: 232px;
  padding: 0 !important;
  border-radius: 0 !important;
}

.swal2-actions {
  /* justify-content: space-evenly; */
  align-items: end !important;
  margin: 0 !important;
  /* margin-left: 20% !important; */
  /* margin-right: 20% !important; */
}

.swal2-close {
  content: url('./assets/x.svg') !important;
  margin-right: 1%;
  margin-top: 1%;
  width: auto;
  height: auto;
}

/* Swal end*/

/* Drop Down Master*/
.drop-down {
  height: 20%;
}

/*Drop Down Master */

