.responsive-div {
    display: none;
}

img {
    cursor: pointer;
}


.responsive-td,.table-display-value {
    /* position: absolute; */
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis;
    max-width: 100%;
    width: fit-content;
}

.responsiveTable thead {
    z-index: 3;
    width: 100%;
}
.responsiveTable tbody ,.responsiveTable tr{
    width: 100%;
}

.table-th{
    display: flex;
    align-items: center;
    width: max-content;
}

.table-icon{
    float: right;
    margin-left: 5px;
   height:30px;
  
}

.custom-grid-table{
    overflow: auto;
    max-width: 97vw;
    margin-bottom: 10px;
}



@media only screen and (max-width: 767px) {

    .responsiveTable{
        display: none;
    }
    /*
      Force table elements to not behave like tables anymore
      Hide table headers (but not display: none;, for accessibility)
    */
    .responsive-div {
        display: flex !important;
        flex-direction: column !important;
    }

    .responsive-row {
        display: flex !important;
        flex-direction: row !important;
    }

   
    
}