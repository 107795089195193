
.g-input select.selected {
  padding-left: 15px !important;
}
.g-input select {
  border: 1px solid #ddd;
  display: block;
  padding: 10px 10px 10px 15px;
  border-radius: 0;
  width: 100%;
  box-sizing: border-box;
  font-family: "WorkSans-Regular";
  font-size: 14px;
  color: #6F6F6F;
}

.g-input select:focus {
  outline: 0;
  font-family: "WorkSans-SemiBold";
  font-size: 14px;
  color: #000000;
}

.g-input label.active,
.g-input Select:not(:placeholder-shown)+label{
  transform: translateX(6px) translateY(-81%);
  font-size: 14px;
  background-color: #fff;
}
.g-input select:focus + label {
  transform: translateX(6px) translateY(-81%);
  font-size: 14px;
  background-color: #fff;
}

