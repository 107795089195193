.clear-search {
    position: absolute;
    cursor: pointer;
    visibility: hidden;
    top: 4px;
    right: 30px;
}

.search-div:hover .clear-search {
    visibility: visible;
}

.search-div {
    position: relative;
}